export default class ReglementFactureRowOblige {
  constructor(data) {
    this.date_echeance = data.date_echeance;
    this.montant_a_regle = data.reste;
    this.montant_a_regle_initial = 0;
    this.montant_regle = data.montant_regle;
    this.montant_regle_initial = data.montant_regle;
    this.num = data.num;
    this.reste = 0;
    this.resteInitial = data.reste;
    this.total_ttc = data.total_ttc;
    this.vendeur = data.vendeur;
    this.vendeur_id = data.vendeur_id;
    this.client = data.client;
    this.client_id = data.client_id;
    this.check = true;
    this.id = data.id;
    this.lot = data.loot;
    this.lot_origin = data.lot_origin;
  }
  static create(data) {
    return new ReglementFactureRowOblige(data);
  }
}
