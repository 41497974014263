import { render, staticRenderFns } from "./reglement.vue?vue&type=template&id=7d2ffdeb&scoped=true&"
import script from "./reglement.vue?vue&type=script&lang=js&"
export * from "./reglement.vue?vue&type=script&lang=js&"
import style0 from "./reglement.vue?vue&type=style&index=0&id=7d2ffdeb&prod&lang=scss&scoped=true&"
import style1 from "./reglement.vue?vue&type=style&index=1&id=7d2ffdeb&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d2ffdeb",
  null
  
)

export default component.exports