export default class ReglementFactureRowMaster {
  constructor(data) {
    this.date_echeance = data.date_echeance;
    this.montant_a_regle = data.reste;
    this.montant_a_regle_initial = 0;
    this.montant_regle = data.montant_regle;
    this.montant_regle_initial = data.montant_regle;
    this.num = data.numero;
    this.reste = 0;
    this.resteInitial = data.reste;
    this.total_ttc =
      Math.round(parseFloat(data.montant_total.replace(' ', '')) * 100) / 100 ||
      0;
    this.vendeur = data.vendeur;
    this.client = data.client;
    this.client_id = data.client_id;
    this.vendeur_id = data.vendeur_id;
    this.check = true;
    this.id = data.id_facture;
  }
  static create(data) {
    return new ReglementFactureRowMaster(data);
  }
}
